import { CaseStudies as UICaseStudies } from '@swordhealth/ui-corporate';

// Import Swiper styles
import 'swiper/css/effect-fade';

export default function CaseStudies({ caseStudyList, mediaType, ...item }) {
  return (
    <UICaseStudies
      {...item}
      mediaType={mediaType}
      caseStudyList={caseStudyList.map(({ image, logo, ...study }) => ({
        ...study,
        ...(mediaType === 'image' && { image }),
        ...(mediaType === 'logo' && { logo: logo.logoBlack }),
      }))}
    />
  );
}
