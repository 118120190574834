import { Counter as UICounter } from '@swordhealth/ui-corporate';

import CounterEl from './CounterEl';

const Counter = ({ counterType, counterValue, ...content }) => {
  return (
    <UICounter
      counter={<CounterEl counterType={counterType} counterValue={counterValue} />}
      {...content}
    />
  );
};

export default Counter;
