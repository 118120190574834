'use client';

import { Layout } from '@/components/core';

import DynamicContent from '../DynamicContent';
import DynamicHero from '../DynamicHero';
import { Disclaimer, Footnotes } from '@/components/shared';

export default function PageContent({
  bannerInfo,
  hero,
  content,
  footnotes,
  settings,
  disclaimer,
}) {
  return (
    <Layout bannerInfo={bannerInfo}>
      {hero && <DynamicHero hero={hero} />}
      {content && <DynamicContent content={content} settings={settings} />}
      {disclaimer && <Disclaimer content={disclaimer} />}
      {footnotes && <Footnotes footNotes={footnotes} />}
    </Layout>
  );
}
