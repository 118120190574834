import { CardGrid } from '@swordhealth/ui-corporate';

export default function IconCardGrid({
  cardBackground,
  cardIconSize,
  cardVariant,
  cardList,
  columnCount,
  ...item
}) {
  return (
    <CardGrid
      {...item}
      columnCount={parseInt(columnCount)}
      cardList={cardList.map((card) => ({
        ...card,
        variant: cardVariant,
        background: cardBackground,
        size: cardIconSize,
      }))}
      cardType="icon"
    />
  );
}
