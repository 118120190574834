'use client';

import { startTransition, useEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import { InView } from 'react-intersection-observer';

import { getEnrollmentMetrics } from '@/services/metrics';

import styles from './counter.module.css';

const COUNTER_TYPES = ['savings', 'enrollments', 'sessions'];
const MS_IN_A_DAY = 86400000;
const INTERVAL_TIMER = 3000; // in ms

function getDiffValue(startDate, increment) {
  const now = new Date();
  const inital = new Date(startDate);
  const diffMs = now - inital;

  return Math.floor((diffMs * increment) / INTERVAL_TIMER);
}

async function getInitialData(counterType) {
  if (COUNTER_TYPES.includes(counterType)) {
    const data = await getEnrollmentMetrics();

    switch (counterType) {
      case 'enrollments':
        return {
          initialValue: data.enrollments,
          startDate: data.updatedAt,
          increment: (data.enrollmentsDaily * INTERVAL_TIMER) / MS_IN_A_DAY,
        };
      case 'savings':
        return {
          initialValue: data.savings,
          startDate: data.updatedAt,
          increment: (data.savingsDaily * INTERVAL_TIMER) / MS_IN_A_DAY,
        };
      case 'sessions':
        return {
          initialValue: data.sessions,
          startDate: data.updatedAt,
          increment: (data.sessionsDaily * INTERVAL_TIMER) / MS_IN_A_DAY,
        };
      case 'custom':
        return {
          initialValue: data.sessions,
          startDate: data.updatedAt,
          increment: (data.sessionsDaily * INTERVAL_TIMER) / MS_IN_A_DAY,
        };
      default:
        return null;
    }
  }

  return null;
}

const CounterAnimation = ({ increment, prefix, initialValue }) => {
  const countUpRef = useRef(null);
  const value = useRef(initialValue);
  const defaultConfigs = {
    ref: countUpRef,
    duration: INTERVAL_TIMER / 1000,
    prefix,
    redraw: true,
    startOnMount: false,
    preserveValue: true,
  };
  const { start: startLinearIncrement } = useCountUp({
    ...defaultConfigs,
    useEasing: false,
    start: value.current - increment,
    end: value.current,
    onEnd: ({ update }) => {
      value.current = value.current + increment;
      update(value.current);
    },
  });
  const { start: startInitialAnimation } = useCountUp({
    ...defaultConfigs,
    delay: 0.3,
    start: value.current - Math.min(value.current * 0.01, 10000),
    end: value.current - increment,
    onEnd: () => {
      startLinearIncrement();
    },
  });

  return (
    <InView
      as="div"
      threshold={0.1}
      triggerOnce={true}
      onChange={(visible) => {
        if (!visible) return;

        startTransition(() => {
          startInitialAnimation();
        });
      }}
    >
      <span ref={countUpRef} />
    </InView>
  );
};

const CounterApiAnimation = ({ counterType }) => {
  const prefix = useRef(counterType === 'savings' ? '$' : '');
  // Initial value is calculated by a rule of 3:
  // diffInMsSinceStartDate * increment / incrementInterval
  // increment - is the value that will be added to the counter every incrementInterval
  // incrementInterval - is the time in milliseconds that the increment value will be added to the counter
  // startDate - is the date that the counter started
  // initialValue - is the value that the counter should have at the startDate
  const [{ initialValue, increment }, setData] = useState({
    initialValue: 0,
    startDate: new Date(),
    increment: 1,
  });

  useEffect(() => {
    getInitialData(counterType)
      .then((data) => {
        if (!data) return;

        setData({
          ...data,
          initialValue: data.initialValue + getDiffValue(data.startDate, data.increment),
        });
      })
      .catch((error) => {
        console.error('Error fetching initial data for CtaCounter', error);
      });
  }, [counterType]);

  if (initialValue) {
    return (
      <CounterAnimation
        increment={increment}
        prefix={prefix?.current}
        initialValue={initialValue}
      />
    );
  }

  return <div className={styles.counterPlaceholder} />;
};

export default CounterApiAnimation;
