import { CardGrid } from '@swordhealth/ui-corporate';

export default function IconCardGrid({
  cardBackground,
  cardContentAlign,
  cardList,
  columnCount,
  ...item
}) {
  return (
    <CardGrid
      {...item}
      columnCount={parseInt(columnCount)}
      cardList={cardList.map((card) => ({
        ...card,
        xAlign: cardContentAlign,
        background: cardBackground,
      }))}
    />
  );
}
