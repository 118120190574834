import CounterApiAnimation from './CounterApiAnimation';
import CounterStaticAnimation from './CounterStaticAnimation';

const CounterEl = ({ counterType, counterValue }) => {
  if (counterType === 'custom') {
    return <CounterStaticAnimation counterValue={counterValue} />;
  }

  return <CounterApiAnimation counterType={counterType} />;
};

export default CounterEl;
