'use client';

import dynamic from 'next/dynamic';
import clsx from 'clsx';

const Animation = dynamic(() => import('@/components/v2/PanelList/Animation'));
const Media = dynamic(() => import('@/components/shared/Media/Media'));

import styles from './panelList.module.css';

export default function PanelMedia({ animation, selected, media, offset = false }) {
  return (
    <div className={clsx(styles.mediaContent, { [styles.mediaOffset]: offset })}>
      {animation && <Animation animation={animation} selected={selected} />}
      {!animation && media && <Media {...media?.media} objectFit={media?.mediaFit} />}
    </div>
  );
}
