import CalculatorRegionIndustry from './CalculatorRegionIndustry';

export default function Calculator({ calculatorType, ...props }) {
  switch (calculatorType) {
    case 'roiRegionIndustry':
      return <CalculatorRegionIndustry {...props} />;
    default:
      return null;
  }
}
