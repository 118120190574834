'use client';

import { startTransition, useMemo, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { useCountUp } from 'react-countup';

import styles from './counter.module.css';

const CounterAnimation = ({ end, prefix, suffix }) => {
  const countUpRef = useRef(null);
  const { start } = useCountUp({
    ref: countUpRef,
    startOnMount: false,
    start: Math.max(0, end - 100),
    end,
    prefix,
    suffix,
    useEasing: false,
  });

  return (
    <InView
      as="div"
      threshold={0.1}
      triggerOnce={true}
      onChange={(visible) => {
        if (!visible) return;

        startTransition(() => {
          start();
        });
      }}
    >
      <span ref={countUpRef} />
    </InView>
  );
};

const CounterStaticAnimation = ({ counterValue }) => {
  const parsedValue = useMemo(() => {
    let parsed = {};

    counterValue.replace(/(.*?)([0-9]*)(.*?)$/, (_, $1, $2, $3) => {
      parsed = {
        prefix: $1,
        end: parseFloat($2),
        suffix: $3,
      };
    });

    return parsed;
  }, [counterValue]);

  if (!parsedValue.end) {
    return <div className={styles.counterPlaceholder} />;
  }

  return <CounterAnimation {...parsedValue} />;
};

export default CounterStaticAnimation;
