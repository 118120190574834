import dynamic from 'next/dynamic';

import { MediaContainer as UIMediaContainer } from '@swordhealth/ui-corporate';

const Animation = dynamic(() => import('@/components/v2/Animation'));
const Media = dynamic(() => import('@/components/shared/Media/Media'));

export default function MediaContainer({ animation, media, ...props }) {
  return (
    <UIMediaContainer {...props}>
      {animation && <Animation animation={animation} />}
      {!animation && media && <Media {...media?.media} objectFit={media?.mediaFit} />}
    </UIMediaContainer>
  );
}
