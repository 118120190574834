'use-client';

import { useMemo } from 'react';
import { Media } from '@/components/shared';
import { Hero } from '@swordhealth/ui-corporate';
import dynamic from 'next/dynamic';

const HeroAnimation = dynamic(() => import('@/components/v2/HeroAnimation'));

export default function DynamicHero({ hero }) {
  const mediaRatioAnimation = useMemo(() => (hero[0].animation === 'roi' ? '1:1' : '3:2'), [hero]);

  if (!hero) {
    console.error("Please provide a 'hero' property to the DynamicHero");
    return null;
  }

  return (
    <>
      {hero.map(({ __component, animation, media, ...item }, index) => {
        const id = `hero-${index}`;
        switch (__component) {
          case 'hero.hero-animation':
            return (
              <Hero {...item} key={id} id={id} mediaRatio={mediaRatioAnimation}>
                <HeroAnimation animation={animation} />
              </Hero>
            );
          case 'hero.hero-media':
            return (
              <Hero {...item} mediaRatio={media?.mediaRatio || undefined} key={id} id={id}>
                <Media {...media?.media} objectFit={media?.mediaFit} />
              </Hero>
            );
          default:
            return null;
        }
      })}
    </>
  );
}
